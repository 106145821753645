import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import title_image from '../images/titles/industries.jpg'

const pageTitle = "Industry Expertise";
const pageSlug = "industry-expertise";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="industry-expertise">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">No matter what your industry is, we always strive to 
            deliver up-to-date industry expertise that can help find your solution.</h6>
        </div>
      </section>

      <section id="p_industry_expetise" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    No matter where you are on your journey, Nuerex's digital consulting service can help you. By 
                    assessing your strategy, planning your course, and implementing solutions for real results.
                    </h3>
                    <p>We constantly strive to provide industry consulting and advice that can help your organization 
                        overcome any business challenges.</p>
                    <p>Here at Nuerex we serve our clients by providing industry consulting expertise that allows them 
                        to solve their biggest business challenges. We understand the importance of industry knowledge, 
                        it’s how we understand your challenges and offer long term lasting solutions.</p>
                    <span className="break"></span>
                    <h4>Industry Consulting Overview</h4>
                    <p>Our experts have significant experience in dealing with many nuances across a broad range of 
                        industries, we understand the challenges in your value chain. We have a wealth of experience 
                        to share with you in helping your organization transform to increase profit growth and create 
                        an outstanding customer experience. We deliver consulting services in a variety of ways: role 
                        based engagements, teaming arrangements, and deliverable based projects. Regardless of the 
                        consulting approach that is needed for you, we will bring the team members that possess the 
                        right skills and corresponding industry expertise to ensure a successful delivery on all your 
                        initiatives.</p>
                    <span className="break"></span>
                    <h4>Our Specialties</h4>
                    <p>We serve these four industries with deep knowledge and expertise.</p>
                    <h5>Healthcare</h5>
                    <p>We can provide you with creative solutions through innovative planning, 
                        process improvement and the use of IT.</p>
                    <h5>Energy & Utilities</h5>
                    <p>Our team specialists deliver quantifiable results that can meet your unique business 
                        and technology requirements for energy and utility companies.</p>
                    <h5>Financial Services</h5>
                    <p>We take great pride in optimizing business processes and modernizing technology solutions.</p>
                    <h5>Insurance</h5>
                    <p>We help insurers increase their operational efficiency, customer service and profitability.</p>
                    <span className="break"></span>
                    <h4>Other Industry Services</h4>
                    <p>Our consultants bring insights and improvements to other key industry sectors.</p>
                    <p><b>LOGISTICS/SUPPLY CHAIN:</b> We help our clients release trapped value in their 
                    logistics networks to reduce costs and create value through innovative solutions.</p>
                    <p><b>RETAIL:</b> When working with retail partners we can optimize every facet of a product's 
                        life cycle in order to gain significant competitive advantage.</p>
                    <p><b>TRAVEL:</b> We help travel services develop adaptable and cross-platform business process, 
                        to improve customer satisfaction and operational efficiency.</p>
                    <p><b>GOVERMENT:</b> By using the best commercial practices we can meet the accountability 
                        needs of public sector organizations.</p>
                    <p><b>MANUFACTURING/PRODUCTS:</b> We help build strong global brands, products and supply 
                        chains with innovative solutions.</p>
                    <span className="break"></span>
                    <div className="contact-box">
                    <h3>Want to learn more about what Nuerex can do for your industry?</h3>  
                    <span className="break"></span>
                    <div className="button-area">
                        <Link to="/contact-us" className="button default">Contact us today</Link>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </section>


      </Layout>
    )
  }
}

export default BusinessConsulting
